import { ReactComponent as Sunny } from '../svg/wi-day-sunny.svg'
import { ReactComponent as MostlySunny } from '../svg/wi-day-sunny-overcast.svg'
import { ReactComponent as Rain } from '../svg/wi-rain.svg'
import { ReactComponent as Cloudy } from '../svg/wi-cloudy.svg'
import { ReactComponent as DayCloudy } from '../svg/wi-day-cloudy.svg'
import { ReactComponent as SunnyOvercast } from '../svg/wi-day-sunny-overcast.svg'
import { ReactComponent as RainMix } from '../svg/wi-day-rain-mix.svg'
import { ReactComponent as Showers } from '../svg/wi-day-showers.svg'

import { PrecisCode } from '../utils/weather.utils'

type WeatherIconProps = {
    precisCode: PrecisCode
    width: string
    height: string
    color: string
}

function WeatherIcon(props: WeatherIconProps): JSX.Element {
    const { precisCode, width, height, color } = props
    const getIcon = (precis: PrecisCode) => {
        switch (precis) {
            case 'fine':
                return <Sunny fill={color} width={width} height={height} />
            case 'mostly-fine':
                return (
                    <MostlySunny fill={color} width={width} height={height} />
                )
            case 'high-cloud':
            case 'cloudy':
                return <Cloudy fill={color} width={width} height={height} />
            case 'partly-cloudy':
            case 'mostly-cloudy':
                return <DayCloudy fill={color} width={width} height={height} />
            case 'overcast':
                return (
                    <SunnyOvercast fill={color} width={width} height={height} />
                )
            case 'showers-rain':
                return <Rain fill={color} width={width} height={height} />
            case 'chance-shower-cloud':
                return <Rain fill={color} width={width} height={height} />
            case 'chance-shower-fine':
                return <RainMix fill={color} width={width} height={height} />
            case 'shower-or-two':
                return <Showers fill={color} width={width} height={height} />
            default:
                return null
        }
    }
    return <>{getIcon(precisCode)}</>
}

export default WeatherIcon
