// Colours
export enum COLOURS {
    Primary = '#b3f66b',
    Secondary = '#ae6bf6',
    Tertiary = '#F66B6E',
    Blue = '#6ba2f6',
    Orange = '#f6d26a',
    DarkGrey = '#A9A9A9',
    Black = '#000000',
    White = '#FFFFFF',
}
