import { SupabaseClient } from '@supabase/supabase-js'
import { TempReading } from '../types/temp.types'

export type DayStats = {
    count: number
    min: number
    max: number
}

export const getLatestTempReading = async (
    client: SupabaseClient,
    locationId: number
): Promise<TempReading | null> => {
    const result = await client
        .from('tempReadings')
        .select(`id,created_at,location,temp`)
        .eq('location', locationId)
        .order('created_at', { ascending: false })
        .limit(1)
    const { data, error } = result
    if (!error && data && data.length > 0) {
        return data[0] as TempReading
    } else {
        return null
    }
}

export const getTodayStats = async (
    client: SupabaseClient,
    location: number
): Promise<DayStats | undefined> => {
    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate())
    return getStats(client, location, yesterday)
}

export const getYesterdayStats = async (
    client: SupabaseClient,
    location: number
): Promise<DayStats | undefined> => {
    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)
    return getStats(client, location, yesterday)
}

const getStats = async (
    client: SupabaseClient,
    location: number,
    date: Date
) => {
    const { data, error } = await client.rpc('stats', {
        location_id: location,
        date_string: date,
    })
    if (!error && data) {
        return {
            count: data.total,
            min: data.min,
            max: data.max,
        }
    } else {
        return undefined
    }
}

export const isReadingValid = (reading: TempReading, maxAge: number) => {
    const now = new Date()
    const time = new Date(reading.created_at)
    const difference = now.getTime() - time.getTime()
    return difference < maxAge
}
