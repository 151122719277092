import { useState, useEffect } from 'react'
import { SupabaseClient } from '@supabase/supabase-js'
import { TempReading } from '../types/temp.types'
import {
    getLatestTempReading,
    getYesterdayStats,
    getTodayStats,
    isReadingValid,
} from '../utils/temp.utils'
import { supabase } from '../utils/supabase.utils'
import { DayStats } from '../utils/temp.utils'
import { COLOURS } from '../constants'
import { useInterval } from '../utils/useInterval'
import { getSecondsSinceTime } from '../utils/time.utils'
import { WeatherAPIResponse } from '../utils/weather.utils'
import { Link } from 'react-router-dom'
import WeatherForecast from '../components/WeatherForecast'
import { getRandomSaying } from '../utils/sayings.utils'
import {
    WaterLevelReading,
    calcuateWaterVolume,
    calculateWaterHeight,
    calculateWaterPercentFull,
    getCurrentWaterLevel,
} from '../utils/waterLevel.utils'
import { CityAnimation } from '../components/CityAnimation'
import { FermentationStatus } from '../utils/fermentationStatus.utils'

const INDOOR_LOCATION_ID = 3
const OUTDOOR_LOCATION_ID = 1
const MAX_TEMP_READING_AGE = 900000

function HomeStatsShorts() {
    const [supabaseClient, setSupabaseClient] = useState<SupabaseClient>()
    const [currentIndoorTemp, setCurrentIndoorTemp] = useState<TempReading>()
    const [currentOutdoorTemp, setCurrentOutdoorTemp] = useState<TempReading>()
    const [todayStats, setTodayStats] = useState<DayStats>()
    const [yesterdayStats, setYesterdayStats] = useState<DayStats>()
    const [weatherData, setWeatherData] = useState<WeatherAPIResponse>()
    const [waterLevel, setWaterLevel] = useState<WaterLevelReading>()
    const [fermentationStatus, setFermentationStatus] =
        useState<FermentationStatus>()

    const todayWeatherEntry = weatherData?.forecasts.weather.days[0].entries[0]
    const tomorrowWeatherEntry =
        weatherData?.forecasts.weather.days &&
        weatherData?.forecasts.weather.days.length > 1 &&
        weatherData?.forecasts.weather.days[1].entries[0]

    const todayRainfallEntry =
        weatherData?.forecasts.rainfall.days[0].entries[0]
    const tomorrowRainfallEntry =
        weatherData?.forecasts.rainfall.days &&
        weatherData?.forecasts.rainfall.days.length > 1 &&
        weatherData?.forecasts.rainfall.days[1].entries[0]

    const getWeatherData = () => {
        fetch(
            'https://api.ejon.es/api/getWeather?code=Qdl61gdQWp_cOPZeSosYt-_Xg71HYeDlhXwv4ESEanKvAzFuNw49GQ=='
        ).then((response) => {
            response.json().then((weatherData) => {
                setWeatherData(weatherData)
            })
        })
    }

    const getFermentationStatus = () => {
        fetch(
            'https://api.ejon.es/api/getFermentationStatus?code=XcfKeuQuU412g2iILGBCO2vD1HTQILExfNU_B6NRaQRwAzFuugzhWg%3D%3D'
        ).then((response) => {
            response.json().then((fermentationData) => {
                setFermentationStatus(fermentationData)
            })
        })
    }

    useEffect(() => {
        setSupabaseClient(supabase)
        getWeatherData()
        getFermentationStatus()
    }, [])

    useEffect(() => {
        if (supabaseClient) {
            getLatestTempReading(supabaseClient, OUTDOOR_LOCATION_ID).then(
                (result) => {
                    if (result && isReadingValid(result, MAX_TEMP_READING_AGE))
                        setCurrentOutdoorTemp(result)
                }
            )
            getLatestTempReading(supabaseClient, INDOOR_LOCATION_ID).then(
                (result) => {
                    if (result && isReadingValid(result, MAX_TEMP_READING_AGE))
                        setCurrentIndoorTemp(result)
                }
            )
            getTodayStats(supabaseClient, OUTDOOR_LOCATION_ID).then(
                (result) => {
                    setTodayStats(result)
                }
            )
            getYesterdayStats(supabaseClient, OUTDOOR_LOCATION_ID).then(
                (result) => {
                    setYesterdayStats(result)
                }
            )
            getCurrentWaterLevel(supabaseClient, 1).then((result) => {
                setWaterLevel(result)
            })
        }
    }, [supabaseClient])

    useInterval(
        () => {
            if (supabaseClient) {
                getLatestTempReading(supabaseClient, OUTDOOR_LOCATION_ID).then(
                    (result) => {
                        if (
                            result &&
                            isReadingValid(result, MAX_TEMP_READING_AGE)
                        )
                            setCurrentOutdoorTemp(result)
                    }
                )
                getLatestTempReading(supabaseClient, INDOOR_LOCATION_ID).then(
                    (result) => {
                        if (
                            result &&
                            isReadingValid(result, MAX_TEMP_READING_AGE)
                        )
                            setCurrentIndoorTemp(result)
                    }
                )
                getTodayStats(supabaseClient, OUTDOOR_LOCATION_ID).then(
                    (result) => {
                        setTodayStats(result)
                    }
                )
                getYesterdayStats(supabaseClient, OUTDOOR_LOCATION_ID).then(
                    (result) => {
                        setYesterdayStats(result)
                    }
                )
                getCurrentWaterLevel(supabaseClient, 1).then((result) => {
                    setWaterLevel(result)
                })
            }
            getWeatherData()
            getFermentationStatus()
        },
        1000 * 60 * 10
    )

    const secondsSinceLastReading = currentOutdoorTemp
        ? getSecondsSinceTime(currentOutdoorTemp.created_at, new Date())
        : 0

    const roundTemperature = (input: number): string => {
        const isWholeNumber = parseFloat(input.toFixed(0)) === input
        return input.toFixed(isWholeNumber ? 0 : 1)
    }

    if (!currentOutdoorTemp) {
        return (
            <div
                style={{
                    display: 'flex',
                    flex: 1,
                    alignItems: 'center',
                    flexDirection: 'column',
                    height: '100vh',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        marginTop: '20vh',
                    }}
                >
                    <div
                        style={{
                            color: 'white',
                            fontFamily: 'Courier New',
                            fontSize: '1.5em',
                            width: '90vw',
                            textAlign: 'center',
                            marginLeft: '5vw',
                            marginRight: '5vw',
                        }}
                    >
                        Doing some loading like
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div
                style={{
                    height: '100vh',
                    fontFamily: 'Courier New',
                    color: COLOURS.Primary,
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    paddingTop: '20px',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                    }}
                >
                    <div>
                        <div>
                            <span
                                style={{
                                    fontSize: '6em',
                                }}
                            >
                                {roundTemperature(currentOutdoorTemp.temp)}
                            </span>
                            {currentIndoorTemp && (
                                <span
                                    style={{
                                        fontSize: '2em',
                                    }}
                                >
                                    {roundTemperature(currentIndoorTemp.temp)}
                                </span>
                            )}
                        </div>
                        {secondsSinceLastReading} at{' '}
                        {new Date(
                            currentOutdoorTemp.created_at
                        ).toLocaleTimeString()}
                    </div>
                    {waterLevel && (
                        <div
                            style={{
                                paddingLeft: '40px',
                                paddingRight: '20px',
                            }}
                        >
                            <div
                                style={{
                                    position: 'relative',
                                    backgroundColor: '#006400',
                                    width: '100px',
                                    height: '140px',
                                    borderRadius: '20px 20px 0 0',
                                    border: '2px solid #b3f66b',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'flex-end',
                                    fontSize: '3em',
                                }}
                            >
                                <div
                                    style={{
                                        backgroundColor: '#04ADE2',
                                        width: '100px',
                                        height: `${calculateWaterPercentFull(
                                            waterLevel?.reading
                                        )}%`,
                                    }}
                                />
                                <div
                                    style={{
                                        position: 'absolute',
                                        height: '100%',
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    {calculateWaterPercentFull(
                                        waterLevel?.reading
                                    )}
                                    %
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <table
                    style={{
                        paddingTop: '10px',
                    }}
                >
                    <tr>
                        {todayWeatherEntry && todayRainfallEntry && (
                            <WeatherForecast
                                displayName="Today"
                                weatherForecast={todayWeatherEntry}
                                rainfallForecast={todayRainfallEntry}
                            />
                        )}
                    </tr>
                    <tr>
                        {tomorrowWeatherEntry && tomorrowRainfallEntry && (
                            <WeatherForecast
                                displayName="Tomorrow"
                                weatherForecast={tomorrowWeatherEntry}
                                rainfallForecast={tomorrowRainfallEntry}
                            />
                        )}
                    </tr>
                </table>
                <div
                    style={{
                        fontSize: '1em',
                        marginTop: '20px',
                    }}
                >
                    so far today has been{' '}
                    {getRandomSaying().toLocaleLowerCase()} with a high of{' '}
                    {todayStats?.max} and a low {todayStats?.min} over{' '}
                    {todayStats?.count} readings, yesterday was{' '}
                    {getRandomSaying().toLocaleLowerCase()} with a high of{' '}
                    {yesterdayStats?.max} and a low {yesterdayStats?.min} over{' '}
                    {yesterdayStats?.count} readings
                </div>
                {waterLevel && (
                    <div
                        style={{
                            fontSize: '1em',
                            marginTop: '20px',
                        }}
                    >
                        Current water level is{' '}
                        {calculateWaterHeight(waterLevel.reading)}cm or{' '}
                        {calcuateWaterVolume(waterLevel.reading)} litres
                        recorded{' '}
                        {getSecondsSinceTime(
                            `${waterLevel.created_at}+00:00`,
                            new Date()
                        )}
                    </div>
                )}
                {fermentationStatus && (
                    <div
                        style={{
                            fontSize: '1em',
                            marginTop: '20px',
                        }}
                    >
                        Beer is being fermented at{' '}
                        {fermentationStatus.temperature.toFixed(2)} recorded{' '}
                        {getSecondsSinceTime(
                            `${fermentationStatus.lastActivityTime}`,
                            new Date()
                        )}
                    </div>
                )}
                <Link
                    style={{
                        position: 'absolute',
                        right: '10px',
                        top: '10px',
                        fontFamily: 'Courier New',
                        color: COLOURS.Primary,
                    }}
                    to="/"
                >
                    17Hollywood
                </Link>
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#330867',
                    zIndex: -2,
                }}
            ></div>
            <CityAnimation />
        </>
    )
}

export default HomeStatsShorts
