import '../css/CityAnimation.css'

export const CityAnimation = () => {
    return (
        <div
            style={{
                position: 'absolute',
                left: '0px',
                bottom: '0px',
            }}
        >
            <div id="city-at-night">
                <div className="night-container">
                    <div className="building-rowOne">
                        <div className="track"></div>
                        <div className="train-container">
                            <div className="train"></div>
                        </div>
                        <div className="further-train">
                            <div className="train"></div>
                        </div>
                        <div className="windows"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}
