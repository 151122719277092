import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Routes, Route, Outlet, Link } from 'react-router-dom'
import HomeStatsHolywood from './pages/HomeStatsHollywood'
import HomeStatsShorts from './pages/HomeStatsShorts'
import './index.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<HomeStatsHolywood />} />
                    <Route path="107" element={<HomeStatsShorts />} />
                    <Route path="*" element={<NoMatch />} />
                </Route>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
)

function Layout() {
    return <Outlet />
}

function NoMatch() {
    return (
        <div className="container">
            <h2 className="logo-text">Nothing to see here!</h2>
            <Link to="/">Go to the home page</Link>
        </div>
    )
}
