export const getSecondsSinceTime = (input: string, now: Date) => {
    const time = new Date(input)
    const difference = now.getTime() - time.getTime()

    if (difference < 60000) {
        // less than an a minute
        const value = Math.floor(difference / 1000)
        return `${value} ${value > 1 ? 'seconds' : 'second'} ago`
    } else {
        const value = Math.floor(difference / 60000)
        return `${value} ${value > 1 ? 'minutes' : 'minute'} ago`
    }
}
