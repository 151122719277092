import { useState, useEffect } from 'react'
import '../css/WaveAnimation.css'
import Lottie from 'lottie-react'
import coralAnimation from '../coral.json'
import { SupabaseClient } from '@supabase/supabase-js'
import { TempReading } from '../types/temp.types'
import {
    DayStats,
    getLatestTempReading,
    getYesterdayStats,
    isReadingValid,
} from '../utils/temp.utils'
import { supabase } from '../utils/supabase.utils'
import { Link } from 'react-router-dom'

const LOCATION_ID = 2
const MAX_TEMP_READING_AGE = 900000

enum TIDE_STATES {
    allWayIn = 'all the way in',
    allWayOut = 'all the way out',
    quarterWayIn = 'quarter of the way in',
    quarterWayOut = 'quarter of the way out',
    thirdWayIn = 'a third of the way in',
    thirdWayOut = 'a third of the way out',
    halfWayIn = 'half way in',
    halfWayOut = 'half way out',
    twoThirdsWayIn = 'two thirds of the way in',
    twoThirdsWayOut = 'two thirds of the way out',
    mostWayOut = 'most of the way out',
    mostWayIn = 'most of the way in',
}

const LOADING_MESSAGE = [
    'Loading fresh sea data',
    'pop pop 🍿',
    'Checking if my socks are wet',
    'Swimming through the data waves, hold on',
    'Sailing the digital seas to fetch your tide info.',
    "Hold tight, we're fishing for tide data!",
    'Just hold your seahorses... Tide data is on its way',
    'Unleashing the kraken to fetch your tide info 🦑',
    "We're reeling in the latest tides for you!",
    'Casting our nets for accurate tide info...',
    'Anchors aweigh! Loading your tide data.',
    "Surf's up! Fetching the latest tide details.",
    'Diving deep to bring you tide information...',
    'Sailing through data... Please wait.',
    'Catching the perfect wave of tide data.',
    'Navigating the seas to find your tides...',
    "We're combing the beach for tide information.",
    'Plunging into the depths for tide data...',
    'Mapping out the tides just for you.',
    'Charting the course for accurate tide data.',
    'Floating your tide data to the surface...',
    'Bringing the tides to you... Please hold on.',
    'Setting sail to gather tide details...',
    'Hauling in the latest tide figures...',
    'Exploring the ocean for tide data...',
    'Gathering seashells and tide information...',
    'Cruising through the waves of data...',
    'On a treasure hunt for tide data...',
    'Scouring the sea floor for tide info...',
    'Launching our vessel for tide data...',
    'Breezing through the data... Please wait.',
    'Fetching tide data from the deep blue sea...',
    'Skimming the surface for tide details...',
    'Embarking on a tide data expedition...',
    'Docking soon with tide information...',
    'Wading through the data for you...',
    'Navigating to bring you tide data...',
    'Sailing the data seas for tide info...',
    'Casting off to gather tide data...',
    'Paddling through tide information...',
    'Seas the day! Loading your tide data.',
    'Exploring the currents for tide details...',
    'On a voyage to fetch tide data...',
    'Bobbing along to bring you tide info...',
    'Steering towards tide data accuracy...',
    'Sweeping the ocean for tide data...',
    'Riding the waves of tide information...',
    'Anchoring the latest tide details...',
    'Seashells and tide data coming up...',
    'Hunting for tide data treasures...',
    'Fishing for tide information pearls...',
    'Sailing smoothly to bring you tide data...',
    'On a quest for tide data accuracy...',
    'Charting new waters for tide info...',
    'Navigating the depths for tide details...',
    'Surfing through tide data waves...',
    'Casting a wide net for tide info...',
    'Voyaging through tide data streams...',
    'Floating your tide details ashore...',
    'Plunging into the sea of tide data...',
    'Anchors down, tide data coming up...',
    'Navigating through tide information...',
    'Skimming tide data just for you...',
    'Diving for the latest tide info...',
    'Sailing to find your tide data...',
    'Fetching tide info from the ocean depths...',
    "Bringing the sea's secrets to you...",
    'Navigating through waves of data...',
    'Exploring the tides for you...',
    'Sweeping the sea for tide info...',
    'Setting sail for tide accuracy...',
    'Fishing the ocean for tide details...',
    'Gathering tide data from the deep...',
    'Charting the tides just for you...',
    'Surfing the tide data waves...',
    'Anchoring in for tide information...',
    'Wading through data streams...',
    'Navigating to tide data shores...',
    'Sailing through tide info seas...',
    'Casting off for tide accuracy...',
    'Diving deep into tide data...',
    'Anchoring the tide information...',
    'Sweeping the seas for tide data...',
    'Exploring ocean depths for info...',
    'Charting our course for tide data...',
    'Fishing for accurate tide details...',
    'Voyaging through tide information...',
    'Breezing through tide data...',
    'Navigating tide data currents...',
    'Sailing to data shores...',
    'Gathering the latest tide info...',
    'Fishing for tide information...',
    'Diving into tide data seas...',
    'Cruising through tide details...',
    'Setting sail for tide info...',
    'Wading through tide data...',
    'Skimming the seas for info...',
    'Navigating through tide streams...',
    'Sweeping the sea floor for info...',
    'Charting the course for tide details...',
    'Fishing for data accuracy...',
    'Voyaging to tide info...',
    'Anchoring down for data...',
    'Navigating the ocean for tide data...',
    'Sailing the seas for info...',
    'Diving into data depths...',
    'Casting off for accurate tides...',
]

type TideDirection = 'low' | 'high'

type TideStatsResponse = {
    percentage: number
    previousEntry: string
    previousEntryType: TideDirection
    nextLowTide: string
    nextLowTideHeight: number
    nextHighTide: string
    nextHighTideHeight: number
}

const getTideStateMessage = (percent: number, direction: TideDirection) => {
    if (direction === 'low') {
        // Tide is on its way in
        if (percent < 10) {
            // All way out
            return TIDE_STATES.allWayOut
        } else if (percent < 25) {
            // Quater way in
            return TIDE_STATES.quarterWayIn
        } else if (percent < 40) {
            // Third way in
            return TIDE_STATES.thirdWayIn
        } else if (percent < 50) {
            // Half way in
            return TIDE_STATES.halfWayIn
        } else if (percent < 60) {
            // Half way in
            return TIDE_STATES.halfWayIn
        } else if (percent < 75) {
            // Two thirds way
            return TIDE_STATES.twoThirdsWayIn
        } else if (percent < 90) {
            // Most way in
            return TIDE_STATES.mostWayIn
        } else {
            // All way in
            return TIDE_STATES.allWayIn
        }
    } else {
        // Tide is on its way out
        if (percent < 10) {
            // All way in
            return TIDE_STATES.allWayIn
        } else if (percent < 25) {
            // Quater way out
            return TIDE_STATES.quarterWayOut
        } else if (percent < 40) {
            // Third way out
            return TIDE_STATES.thirdWayOut
        } else if (percent < 50) {
            // Half way out
            return TIDE_STATES.halfWayOut
        } else if (percent < 60) {
            // Half way out
            return TIDE_STATES.halfWayOut
        } else if (percent < 75) {
            // Two thirds out
            return TIDE_STATES.twoThirdsWayOut
        } else if (percent < 90) {
            // Most way out
            return TIDE_STATES.mostWayOut
        } else {
            // All way out
            return TIDE_STATES.allWayOut
        }
    }
}

const getTideTimeText = (input: string): string => {
    const tideDateTime = new Date(input)
    const now = new Date()

    if (!tideDateTime) {
        return 'no idea'
    }

    const difference = tideDateTime.getTime() - now.getTime()

    if (difference < 3600000) {
        // less than an hour
        return `in ${Math.floor(difference / 60000)} minutes`
    } else {
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24)
        if (hours === 1) {
            return 'in about an hour'
        } else {
            return `in about ${hours} hours`
        }
    }
}

function HomeStatsHolywood() {
    const [tideStats, setTideStats] = useState<TideStatsResponse>()
    const [supabaseCient, setSupabaseClient] = useState<SupabaseClient>()
    const [hasTimerExpired, setHasTimerExpired] = useState(false)
    const [loadingMessage, setLoadingMessage] = useState('')
    const [currentOutdoorTemp, setCurrentOutdoorTemp] = useState<TempReading>()
    const [yesterdayStats, setYesterdayStats] = useState<DayStats>()

    useEffect(() => {
        setSupabaseClient(supabase)
    }, [])

    useEffect(() => {
        setLoadingMessage(
            LOADING_MESSAGE[Math.floor(Math.random() * LOADING_MESSAGE.length)]
        )
    }, [])

    useEffect(() => {
        fetch(
            'https://api.ejon.es/api/tideClock?code=WnKY32sx9z-YIsk0Ksof6hi4vNedXMuZlLThsrMmQzh8AzFuucK0Qw=='
        ).then((response) => {
            response.json().then((json) => {
                setTideStats(json)
            })
        })
    }, [])

    useEffect(() => {
        setTimeout(() => {
            setHasTimerExpired(true)
        }, 1500)
    }, [])

    useEffect(() => {
        if (supabaseCient) {
            getLatestTempReading(supabaseCient, LOCATION_ID).then((result) => {
                if (result && isReadingValid(result, MAX_TEMP_READING_AGE))
                    setCurrentOutdoorTemp(result)
            })
            getYesterdayStats(supabaseCient, LOCATION_ID).then((result) => {
                setYesterdayStats(result)
            })
        }
    }, [supabaseCient])

    if (!tideStats || !hasTimerExpired) {
        return (
            <div
                style={{
                    display: 'flex',
                    flex: 1,
                    alignItems: 'center',
                    height: '100vh',
                    flexDirection: 'column',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        marginTop: '20vh',
                    }}
                >
                    <Lottie
                        style={{
                            width: 300,
                            height: 300,
                        }}
                        animationData={coralAnimation}
                        loop={true}
                    />
                    <div
                        style={{
                            color: 'white',
                            fontFamily: 'Courier New',
                            fontSize: '1.5em',
                            width: '90vw',
                            textAlign: 'center',
                            marginLeft: '5vw',
                            marginRight: '5vw',
                        }}
                    >
                        {loadingMessage}
                    </div>
                </div>
            </div>
        )
    }

    const tideStateText = getTideStateMessage(
        tideStats.percentage,
        tideStats.previousEntryType
    )

    const nextLowTideText = `${getTideTimeText(tideStats.nextLowTide)} (${
        tideStats.nextLowTideHeight
    }m)`
    const nextHighTideText = `${getTideTimeText(tideStats.nextHighTide)} (${
        tideStats.nextHighTideHeight
    }m)`

    return (
        <>
            <div className="ocean">
                <div className="wave"></div>
                <div className="wave"></div>
            </div>
            <div
                style={{
                    display: 'flex',
                    height: '100vh',
                    justifyContent: 'center',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        fontFamily: 'Courier New',
                        width: '70vw',
                        color: 'white',
                        paddingTop: '20px',
                    }}
                >
                    <div style={{ fontSize: '3em', marginBottom: '2vh' }}>
                        the tide is {tideStateText}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '1.5em',
                            marginBottom: '2vh',
                        }}
                    >
                        <img
                            width={15}
                            src={'/images/temperature.png'}
                            alt="Temperature"
                        />
                        {currentOutdoorTemp &&
                            currentOutdoorTemp.temp.toFixed(1)}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '2vh',
                        }}
                    >
                        next low tide is {nextLowTideText}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '2vh',
                        }}
                    >
                        next high tide is {nextHighTideText}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '0.5em',
                        }}
                    >
                        a total of {yesterdayStats?.count} temperature readings
                        were taken yesterday with a min of {yesterdayStats?.min}{' '}
                        and a high of {yesterdayStats?.max}
                    </div>
                    <Link
                        style={{
                            position: 'absolute',
                            right: '10px',
                            top: '10px',
                            color: 'white',
                        }}
                        to="/107"
                    >
                        107Shorts
                    </Link>
                </div>
            </div>
        </>
    )
}

export default HomeStatsHolywood
