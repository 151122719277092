import WeatherIcon from './WeatherIcon'
import {
    WeatherAPIResponseWeatherEntry,
    WeatherAPIResponseRainfallEntry,
} from '../utils/weather.utils'
import { COLOURS } from '../constants'

type WeatherForecastProps = {
    weatherForecast: WeatherAPIResponseWeatherEntry
    rainfallForecast: WeatherAPIResponseRainfallEntry
    displayName: string
}

function WeatherForecast(props: WeatherForecastProps): JSX.Element {
    const {
        weatherForecast: { dateTime, precis, precisCode, min, max },
        rainfallForecast: { startRange, endRange },
        displayName,
    } = props
    const date = new Date(dateTime.split(' ')[0])
    const dateString = `${date.getDate()}/${date.getMonth()}`
    const rainfallText =
        startRange && endRange ? `${startRange}-${endRange}` : endRange
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                borderWidth: '2px',
                borderRadius: '5px',
                borderColor: COLOURS.Primary,
                borderStyle: 'solid',
                fontFamily: 'Courier New',
                fontSize: '1.5em',
                padding: '5px',
                marginBottom: '5px',
                alignSelf: 'flex-start',
            }}
        >
            <div
                style={{
                    marginTop: '10px',
                }}
            >
                <WeatherIcon
                    precisCode={precisCode}
                    color={COLOURS.Primary}
                    width={'2em'}
                    height={'2em'}
                />
            </div>
            <div
                style={{
                    paddingRight: '15px',
                }}
            >
                <div>{displayName}</div>
                <div>{dateString}</div>
            </div>
            <div
                style={{
                    paddingRight: '10px',
                }}
            >
                {precis}
            </div>
            <div style={{ paddingRight: '10px' }}>
                {min}-{max}
            </div>
            <div>{rainfallText}mm</div>
        </div>
    )
}

export default WeatherForecast
