export const sayings: string[] = [
    'Absolutely amazing',
    'Super cool',
    'Totally awesome',
    'Really rad',
    'Extremely excellent',
    'Wonderfully wicked',
    'Incredibly impressive',
    'Fantastically fabulous',
    'Brilliantly brilliant',
    'Exceptionally excellent',
    'Remarkably rad',
    'Amazingly awesome',
    'Unbelievably unique',
    'Phenomenally fantastic',
    'Extraordinarily epic',
    'Delightfully dazzling',
    'Outrageously outstanding',
    'Superbly superb',
    'Jaw-droppingly joyous',
    'Terrifically terrific',
    'Mind-blowingly magnificent',
    'Unquestionably unparalleled',
    'Astoundingly awesome',
    'Blissfully brilliant',
    'Gloriously great',
    'Charmingly cool',
    'Tremendously top-notch',
    'Flawlessly fabulous',
    'Exceptionally exquisite',
    'Breathtakingly beautiful',
    'Ravishingly remarkable',
    'Sensationally superb',
    'Perfectly perfect',
    'Outstandingly original',
    'Stupendously stunning',
    'Wickedly wonderful',
    'Magnificently marvelous',
    'Delightfully dazzling',
    'Strikingly stellar',
    'Dazzlingly divine',
    'Terrifically tremendous',
    'Unbelievably unbelievable',
    'Thrillingly terrific',
    'Brilliantly breathtaking',
    'Wonderfully wondrous',
    'Fantastically phenomenal',
    'Perfectly prime',
    'Sublimely spectacular',
    'Exceptionally excellent',
    'Gloriously grand',
    'Impeccably impressive',
    'Delightfully delightful',
    'Miraculously mind-blowing',
    'Incredibly incredible',
    'Marvelously magical',
    'Awesomely amazing',
    'Radically rad',
    'Superbly stunning',
    'Fabulous beyond belief',
    'Exquisitely extraordinary',
    'Tremendously terrific',
    'Unparalleled in perfection',
    'Wonderfully wonderful',
    'Blissfully brilliant',
    'Splendidly spectacular',
    'Astonishingly awesome',
    'Phenomenally phenomenal',
    'Outrageously outstanding',
    'Impressively incredible',
    'Fantastically fabulous',
    'Unquestionably unique',
    'Spectacularly superb',
    'Gloriously gorgeous',
    'Brilliantly bold',
    'Marvelously magnificent',
    'Remarkably radiant',
    'Wonderfully whimsical',
    'Perfectly polished',
    'Fantastically fabulous',
    'Dazzlingly delightful',
    'Exceedingly excellent',
    'Ravishingly remarkable',
    'Tremendously tremendous',
    'Unbelievably unbeatable',
    'Fabulous to the max',
    'Staggeringly stellar',
    'Astoundingly awesome',
    'Jaw-droppingly joyous',
    'Superb beyond words',
    'Incredibly inspiring',
    'Remarkably remarkable',
    'Outrageously outstanding',
    'Dazzlingly divine',
    'Brilliantly breathtaking',
    'Wonderfully wondrous',
    'Magnificently marvelous',
    'Sensationally superb',
    'Perfectly perfect',
    'Phenomenally fantastic',
    'Absolutely astonishing',
]
